import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 */
export default function Page({
  data,
  location,
}: {
  data: {
    ghostPage: {
      codeinjection_styles: { [k: string]: any }
      title: string
      html: string
      feature_image?: string
    }
  }
  location: { pathname: string }
}): JSX.Element {
  const { ghostPage: page } = data

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <div className="container">
          <article className="content">
            <h1 className="content-title">{page.title}</h1>

            {/* The main page content */}
            <section
              className="content-body load-external-scripts"
              dangerouslySetInnerHTML={{ __html: page.html }}
            />
          </article>
        </div>
      </Layout>
    </>
  )
}

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      ...GhostPageFields
    }
  }
`
